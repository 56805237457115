import { ContentContainer, FlexBox, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React from 'react';

import { Logo, logosList } from './consts';

const LogoImage = styled.img(
  css({
    width: { _: 24, xs: 32, md: 48 },
    height: { _: 24, xs: 32, md: 48 },
  })
);

export const LearnersWorkAt: React.FC = () => {
  return (
    <ContentContainer px={48} pt={48} pb={96}>
      <Text
        as="h2"
        variant="p-base"
        fontFamily="accent"
        textAlign="center"
        mb={24}
      >
        Our learners work at
      </Text>
      <FlexBox
        as="ul"
        listStyleType="none"
        p={0}
        m={0}
        px={16}
        flexWrap="wrap"
        columnGap={{ _: 16, xs: 24, sm: 48, lg: 64 }}
        rowGap={24}
        justifyContent="center"
        alignItems="center"
      >
        {logosList.map((logo: Logo) => {
          return (
            <FlexBox key={logo.alt} as="li" m={0} p={0} center>
              <LogoImage src={logo.src} alt={logo.alt} />
            </FlexBox>
          );
        })}
      </FlexBox>
    </ContentContainer>
  );
};
