import { Anchor, Box, FlexBox, Text, TextProps } from '@codecademy/gamut';
import { GamutIconProps } from '@codecademy/gamut-icons';
import { css, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { useTracking } from '@mono/data-use-tracking';
import { UserClickData } from '@mono/tracking';
import { getCatalogPath } from '@mono/util-url';
import { ComponentType } from 'react';

interface SkillTileProps {
  title: string;
  slug: string;
  classification: string;
  Icon: ComponentType<GamutIconProps>;
  trackingData: Omit<Partial<UserClickData>, 'slug'>;
  ariaLabel?: string;
}

const SkillAnchor = styled(Anchor)(
  css({
    backgroundColor: 'black',
    borderColor: theme.colors['border-tertiary'] as TextProps['color'],
    borderRadius: 'lg',
    border: 1,
    transition: 'background-color 0.2s ease',
    '&:hover, &:focus': {
      border: 1,
      background: theme.colors['background-hover'],
    },
  })
);

export const SkillTile: React.FC<SkillTileProps> = ({
  title,
  slug,
  classification,
  Icon,
  trackingData,
  ariaLabel,
}) => {
  const { trackUserClick } = useTracking();

  const href = getCatalogPath({ categoryType: classification, category: slug });

  return (
    <SkillAnchor
      href={href}
      onClick={() => {
        trackUserClick({ target: 'skill_tile', href, slug, ...trackingData });
      }}
      variant="interface"
      width="100%"
    >
      <FlexBox py={8} px={16} height={64} alignItems="center">
        <Box display={{ _: 'none', sm: 'flex' }}>
          <Icon size={32} />
        </Box>
        <Text fontFamily="accent" pl={{ _: 0, sm: 16 }} aria-label={ariaLabel}>
          {title}
        </Text>
      </FlexBox>
    </SkillAnchor>
  );
};
